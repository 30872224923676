export enum AnalyticEventNameNew {
  AutoPlayerInitiated = 'auto_player_initiated',
  AutoPlayerStarted = 'auto_player_started',
  AutoPlayerInterrupted = 'auto_player_interrupted',
  AutoPlayerFatalError = 'auto_player_fatal_error',

  ClickLanguageChange = 'click_language_change',
  ClickLanguageSelect = 'click_language_select',
  ClickKinomDislikeSelect = 'click_kinom_dislike_select',
  ShowKinomDislikeSettings = 'show_kinom_dislike_settings',
  ClickKinomDislikeSettings = 'click_kinom_dislike_settings',
  ShowMyChannelOnboardPopup = 'show_mychannel_onboard_popup',
  ClickKinomShareLink = 'click_kinom_share_link',

  ClickMyChannelFeedSelect = 'click_mychannel_feed_select',
  ClickKinomHorizontalState = 'click_kinom_horizontal_state',
  ClickMyChannelFeedSettings = 'click_mychannel_feed_settings',

  AutoSubscribeCloudpaymentsDisplayed = 'auto_subscribe_cloudpayments_displayed',
  AutoMyChannelListUpdated = 'auto_mychannel_list_updated',
  ClickSubscribeManage = 'click_subscribe_manage',
  ClickSubscribeManageRenew = 'click_subscribe_manage_renew',
  ClickSubscribeManageBack = 'click_subscribe_manage_back',
  ClickSubscribeManageChange = 'click_subscribe_manage_change',
  ClickSubscribeManageOn = 'click_subscribe_manage_on',
  ClickSubscribeManageCancel = 'click_subscribe_manage_cancel',
  ClickSubscribeManageSave = 'click_subscribe_manage_save',
  ShowSubscribeManagePopup = 'show_subscribe_manage_popup',
  ClickSubscribeManagePopup = 'click_subscribe_manage_popup',
  ShowSubscribeCancelReasons = 'show_subscribe_cancel_reasons',
  ClickSubscribeCancelReasonsStay = 'click_subscribe_cancel_reasons_stay',
  ClickSubscribeCancelReasonsProceed = 'click_subscribe_cancel_reasons_proceed',
  ShowSubscribeCancelLastChance = 'show_subscribe_cancel_last_chance',
  ClickSubscribeCancelLastChance = 'click_subscribe_cancel_last_chance',

  ClickParentalPasswordRecovery = 'click_parental_password_recovery',
  AutoParentalPasswordInvalid = 'auto_parental_password_invalid',
  AutoParentalPasswordValid = 'auto_parental_password_valid',

  AutoParentalSupportDisplayed = 'auto_parental_support_displayed',
  AutoParentalKidsAccessDisplayed = 'auto_parental_kids_access_displayed',
  ClickParentalKidsAccessAnswer = 'click_parental_kids_access_answer',
  AutoParentalCodeCreateDisplayed = 'auto_parental_code_create_displayed',
  ClickParentalCodeCreate = 'click_parental_code_create',
  AutoParentalProfilesDisplayed = 'auto_parental_profiles_displayed',
  ClickParentalProfileSelect = 'click_parental_profile_select',
  AutoParentalCodeEnterDisplayed = 'auto_parental_code_enter_displayed',
  AutoParentalCodeInvalid = 'auto_parental_code_invalid',
  AutoParentalPasswordForgotDisplayed = 'auto_parental_password_forgot_displayed',

  ShowRegPasswordPage = 'show_reg_password_page',
  ClickSubscribeOfferChoose = 'click_subscribe_offer_choose',
  ShowLoginPasswordPage = 'show_login_password_page',
  ShowSubscribeOfferPage = 'show_subscribe_offer_page',
  ClickSubscribeAutorenewalDisable = 'click_subscribe_autorenewal_disable',
  ClickSubscribeAutorenewalDisableConfirm = 'click_subscribe_autorenewal_disable_confirm',
  ClickSubscribeAutorenewalDisableCancel = 'click_subscribe_autorenewal_disable_cancel',
  ClickSubscribeManagement = 'click_subscribe_management',
  ClickSubscribePay = 'click_subscribe_pay',
  AutoAuthSuccessRegistration = 'auto_auth_success_registration',
  AutoAuthSuccessAuthorization = 'auto_auth_success_authorization',
  AutoSubscriberCardBound = 'auto_subscribe_card_bound',
  AutoCollectionsDisplayed = 'auto_collections_displayed',
  AutoItemSimilarBeltDisplayed = 'auto_item_similar_belt_displayed',
  AutoKinomNext = 'auto_kinom_next',
  AutoKinomStreaming = 'auto_kinom_streaming',
  AutoMainDsmlBeltDisplayed = 'auto_main_dsml_belt_displayed',
  AutoMainGenresListDisplayed = 'auto_main_genres_list_displayed',
  AutoPlayerStreaming = 'auto_player_streaming',
  AutoSearchResultsFullDisplayed = 'auto_search_results_full_displayed',
  AutoSearchHistoryDisplayed = 'auto_search_history_displayed',
  ClickSearchHistoryItem = 'click_search_history_item',
  ClickSearchVoiceMode = 'click_search_voice_mode',
  AutoSearchVoiceError = 'auto_search_voice_error',
  AutoSearchVoiceSuccess = 'auto_search_voice_success',

  AutoKinomPause = 'auto_kinom_pause',
  AutoKinomPlay = 'auto_kinom_play',

  AutoSearchResultsPreviewDisplayed = 'auto_search_results_preview_displayed',
  AutoStartSession = 'auto_start_session',
  ClickAccountChangeEmail = 'click_account_change_email',
  ClickAccountChangeEmailCancel = 'click_account_change_email_cancel',
  ClickAccountChangeEmailConfirm = 'click_account_change_email_confirm',
  ClickAccountChangePassword = 'click_account_change_password',
  ClickAccountChangePasswordCancel = 'click_account_change_password_cancel',
  ClickAccountChangePasswordConfirm = 'click_account_change_password_confirm',
  ClickAccountChangePhone = 'click_account_change_phone',
  ClickAccountChangePhoneCancel = 'click_account_change_phone_cancel',
  ClickAccountChangePhoneConfirm = 'click_account_change_phone_confirm',
  ClickAccountRemove = 'click_account_remove',
  ClickAccountRemoveCancel = 'click_account_remove_cancel',
  ClickAccountRemoveConfirm = 'click_account_remove_confirm',
  ClickBonusesPromocode = 'click_bonuses_promocode',
  ClickBonusesPromocodeApply = 'click_bonuses_promocode_apply',
  ClickBonusesReferral = 'click_bonuses_referral',
  ClickBonusesReferralCopyLink = 'click_bonuses_referral_copy_link',
  ClickBonusesReferralShare = 'click_bonuses_referral_share',
  ClickCatalogFilterCountryRemove = 'click_catalog_filter_country_remove',
  ClickCatalogFilterCountrySet = 'click_catalog_filter_country_set',
  ClickCatalogFilterGenreRemove = 'click_catalog_filter_genre_remove',
  ClickCatalogFilterGenreSet = 'click_catalog_filter_genre_set',
  ClickCatalogFilterPeriodRemove = 'click_catalog_filter_period_remove',
  ClickCatalogFilterPeriodSet = 'click_catalog_filter_period_set',
  ClickCatalogMovies = 'click_catalog_movies',
  ClickCatalogSeries = 'click_catalog_series',
  ClickCollectionsItemRemove = 'click_collections_item_remove',
  ClickCollectionsItems = 'click_collections_items',
  ClickCollectionsKinomRemove = 'click_collections_kinom_remove',
  ClickCollectionsKinoms = 'click_collections_kinoms',
  ClickDevicesAddTv = 'click_devices_add_tv',
  ClickDevicesRemove = 'click_devices_remove',
  ClickDevicesRemoveCancel = 'click_devices_remove_cancel',
  ClickDevicesRemoveConfirm = 'click_devices_remove_confirm',
  ClickExit = 'click_exit',
  ClickExitCancel = 'click_exit_cancel',
  ClickExitConfirm = 'click_exit_confirm',
  ClickItemCollectionsRemove = 'click_item_collections_remove',
  ClickItemCollectionsSet = 'click_item_collections_set',
  ClickItemKinomBeltScroll = 'click_item_kinom_belt_scroll',
  ClickItemSelectEpisode = 'click_item_select_episode',
  ClickItemShare = 'click_item_share',
  ClickItemSimilarBeltScroll = 'click_item_similar_belt_scroll',
  ClickKinomCollectionsRemove = 'click_kinom_collections_remove',
  ClickKinomCollectionsSet = 'click_kinom_collections_set',
  ClickKinomDislikeRemove = 'click_kinom_dislike_remove',
  ClickKinomDislikeSet = 'click_kinom_dislike_set',
  ClickKinomFullscreenOff = 'click_kinom_fullscreen_off',
  ClickKinomFullscreenOn = 'click_kinom_fullscreen_on',
  ClickKinomHorizontalOff = 'click_kinom_horizontal_off',
  ClickKinomHorizontalOn = 'click_kinom_horizontal_on',
  ClickKinomIncreaseImageOff = 'click_kinom_increase_image_off',
  ClickKinomIncreaseImageOn = 'click_kinom_increase_image_on',
  ClickKinomLikeRemove = 'click_kinom_like_remove',
  ClickKinomLikeSet = 'click_kinom_like_set',
  ClickKinomNext = 'click_kinom_next',
  ClickKinomPause = 'click_kinom_pause',
  ClickKinomPlay = 'click_kinom_play',
  ClickKinomPrevious = 'click_kinom_previous',
  ClickKinomSelect = 'click_kinom_select',
  ClickKinomShare = 'click_kinom_share',
  ClickKinomVolumeDown = 'click_kinom_volume_down',
  ClickKinomVolumeUp = 'click_kinom_volume_up',
  ClickMainContinueBeltScroll = 'click_main_continue_belt_scroll',
  ClickMainContinueItemRemove = 'click_main_continue_item_remove',
  ClickMainDsmlBeltScroll = 'click_main_dsml_belt_scroll',
  ClickMainPromoBeltScroll = 'click_main_promo_belt_scroll',
  ClickPlayer10secForward = 'click_player_10sec_forward',
  ClickPlayer10secRewind = 'click_player_10sec_rewind',
  ClickPlayerBroadcastOff = 'click_player_broadcast_off',
  ClickPlayerBroadcastOn = 'click_player_broadcast_on',
  ClickPlayerCreateKinom = 'click_player_create_kinom',
  ClickPlayerEpisodeNext = 'click_player_episode_next',
  ClickPlayerEpisodePrevious = 'click_player_episode_previous',
  ClickPlayerEpisodeSelect = 'click_player_episode_select',
  ClickPlayerFullscreenOff = 'click_player_fullscreen_off',
  ClickPlayerFullscreenOn = 'click_player_fullscreen_on',
  ClickPlayerPause = 'click_player_pause',
  ClickPlayerPlay = 'click_player_play',
  ClickPlayerPlaytimeSet = 'click_player_playtime_set',
  ClickPlayerSelectLanguage = 'click_player_select_language',
  ClickPlayerSelectQuality = 'click_player_select_quality',
  ClickPlayerSelectSpeed = 'click_player_select_speed',
  ClickPlayerVolumeDown = 'click_player_volume_down',
  ClickPlayerVolumeUp = 'click_player_volume_up',
  ClickPlayerWindowOff = 'click_player_window_off',
  ClickPlayerWindowOn = 'click_player_window_on',
  ClickSearchRequestCancel = 'click_search_request_cancel',
  ClickSearchRequestEntering = 'click_search_request_entering',
  ClickSupportStartChat = 'click_support_start_chat',
  ClickTvBeltScroll = 'click_tv_belt_scroll',
  ClickTvPromoBeltScroll = 'click_tv_promo_belt_scroll',
  GotoAuthPage = 'goto_auth_page',
  GotoAccountPage = 'goto_account_page',
  GotoAppStore = 'goto_app_store',
  GotoRuStore = 'goto_ru_store',
  GotoAppGalleryStore = 'goto_appgallery_store',
  GotoBonusesPage = 'goto_bonuses_page',
  GotoCatalogPage = 'goto_catalog_page',
  GotoCollectionsPage = 'goto_collections_page',
  GotoContactsPage = 'goto_contacts_page',
  GotoDevicesPage = 'goto_devices_page',
  GotoGooglePlay = 'goto_google_play',
  GotoItemPage = 'goto_item_page',
  GotoKinomPage = 'goto_kinom_page',
  GoToMyChannelPage = 'goto_mychannel_page',
  ShowMyChannelPage = 'show_mychannel_page',
  GotoTvChannelPage = 'goto_tvchannel_page',
  ShowTVChannelPage = 'show_tvchannel_page',
  GotoMainPage = 'goto_main_page',
  GotoPlayerPage = 'goto_player_page',
  GotoPrivacyPolicy = 'goto_privacy_policy',
  GotoProfilesPage = 'goto_profiles_page',
  GotoRecommendationTechnologies = 'goto_recommendation_technologies',
  GotoSearchPage = 'goto_search_page',
  GotoSubscribePage = 'goto_subscribe_page',
  GotoSupportPage = 'goto_support_page',
  GotoTvPage = 'goto_tv_page',
  GotoOfferPage = 'goto_offer_page',
  GotoUserAgreement = 'goto_user_agreement',
  ShowAccountPage = 'show_account_page',
  ShowBonusesPage = 'show_bonuses_page',
  ShowCatalogPage = 'show_catalog_page',
  ClickAuthLoginEntering = 'click_auth_login_entering',
  ClickAuthLoginSocials = 'click_auth_login_socials',
  ClickAuthContinue = 'click_auth_continue',
  ClickAuthPasswordEntering = 'click_auth_password_entering',
  ClickAuthPasswordForgot = 'click_auth_password_forgot',
  ShowCollectionsPage = 'show_collections_page',
  ShowDevicesPage = 'show_devices_page',
  ShowItemPage = 'show_item_page',
  ShowKinomPage = 'show_kinom_page',
  ShowMainPage = 'show_main_page',
  ShowPlayerPage = 'show_player_page',
  ShowProfilesPage = 'show_profiles_page',
  ShowSearchPage = 'show_search_page',
  ShowSubscribePage = 'show_subscribe_page',
  ShowSupportPage = 'show_support_page',
  ShowTvPage = 'show_tv_page',
  ShowLoginPage = 'show_login_page',
  ShowAuthPage = 'show_auth_page',
  ShowAuthLoginTv = 'show_auth_login_tv',
  ClickAuthLoginTv = 'click_auth_login_tv',
  ShowAuthPasswordChange = 'show_auth_password_change',
  ShowRegistrationPage = 'show_reg_page',
  AutoCompilationBeltDisplayed = 'auto_compilation_belt_displayed',
  ClickCompilationBeltScroll = 'click_compilation_belt_scroll',
  GoToCompilationPage = 'goto_compilation_page',
  ShowCompilationPage = 'show_compilation_page',
  GoToGeneralCompilationPage = 'goto_general_compilation_page',
  ClickItemAdditionalMetadata = 'click_item_additional_metadata',
  ClickCatalogRandomItem = 'click_catalog_random_item',
}
