import { AvailableContentType, type Episode, type Media, type Season } from '@package/sdk/src/api';
import { indexOutOfRange, isDefined, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { findRight } from '@PLAYER/player/base/dom';

interface BaseSeriesOptions {
  readonly seasons: Season[];
}

type GetFirstAvailableEpisodeOptions = BaseSeriesOptions;

interface NextAvailableEpisode {
  episode: Episode;
  season: Season;
}

type GetFreeEpisodeOptions = BaseSeriesOptions;

interface GetNextAvailableEpisodeOptions extends BaseSeriesOptions {
  currentSeasonNumber: number;
  currentEpisodeNumber: number;
}

type GetLastAvailableEpisodeOptions = BaseSeriesOptions;

interface GetEpisodeByIdOptions extends BaseSeriesOptions {
  episodeId: string;
}

export default function useSeriesContentHelpers() {
  const isAvailable = (content?: Media) =>
    content?.availability === AvailableContentType.AVAILABLE ||
    content?.availability === AvailableContentType.UNAVAILABLESOON;

  const getEpisodeById = (options: GetEpisodeByIdOptions): Episode | undefined => {
    const { seasons, episodeId } = options;

    for (const season of seasons) {
      const episode = season.episodes.find((episode) => episode.id === episodeId);

      if (episode) {
        return episode;
      }
    }
  };

  const getFirstAvailableEpisode = (options: GetFirstAvailableEpisodeOptions): NextAvailableEpisode => {
    const { seasons } = options;

    // Бэк отдает сезон хотя бы с 1 доступной серией
    const firstAvailableSeason = seasons[0];
    const firstAvailableEpisode = firstAvailableSeason.episodes.find(isAvailable);

    return {
      season: firstAvailableSeason,
      episode: firstAvailableEpisode as Episode,
    };
  };

  const getFreeEpisode = (options: GetFreeEpisodeOptions): Episode => {
    const { seasons } = options;

    // Бэк отдает сезон хотя бы с 1 доступной серией
    const firstAvailableSeason = seasons[0];
    const freeEpisode = firstAvailableSeason?.episodes.find((episode) => episode.accessKind === 'all_users');

    return freeEpisode as Episode;
  };

  const getNextAvailableEpisode = (options: GetNextAvailableEpisodeOptions): NextAvailableEpisode | undefined => {
    const { currentSeasonNumber, currentEpisodeNumber, seasons } = options;

    const startSeasonIndex = seasons.findIndex((season: Season) => currentSeasonNumber === season.number);

    const startEpisodeIndex = seasons[startSeasonIndex].episodes.findIndex(
      (episode: Partial<Episode>) => currentEpisodeNumber === episode.number,
    );

    if (indexOutOfRange(startSeasonIndex)) {
      throw new UnexpectedComponentStateError('startSeasonIndex');
    }

    if (indexOutOfRange(startEpisodeIndex)) {
      throw new UnexpectedComponentStateError('startEpisodeIndex');
    }

    let episodeIndex = startEpisodeIndex + 1;

    function getNextAvailable(startIndex: number, endIndex: number): NextAvailableEpisode | undefined {
      for (let i = startIndex; i < endIndex; i++) {
        for (let j = episodeIndex; j < seasons[i].episodes.length; j++) {
          if (j === startEpisodeIndex && i === startSeasonIndex) {
            return;
          }

          const currentSeason = seasons[i];
          const currentEpisode = currentSeason.episodes[j];

          const matchedEpisode = isAvailable(currentEpisode);

          if (matchedEpisode) {
            const episode = seasons[i].episodes[j];
            const season = seasons[i];

            return {
              episode,
              season,
            };
          }
        }

        episodeIndex = 0;
      }

      if (startIndex !== 0) {
        return getNextAvailable(0, startSeasonIndex);
      }
    }

    return getNextAvailable(startSeasonIndex, seasons.length);
  };

  const getLastAvailableEpisode = (options: GetLastAvailableEpisodeOptions): NextAvailableEpisode => {
    const { seasons } = options;

    let lastAvailableEpisode: Episode | undefined;

    const lastAvailableSeason = findRight(seasons, (season) => {
      lastAvailableEpisode = findRight(season.episodes, isAvailable);

      return isDefined(lastAvailableEpisode);
    });

    if (!lastAvailableSeason || !lastAvailableEpisode) {
      throw new UnexpectedComponentStateError('lastAvailableSeason | lastAvailableEpisode');
    }

    return {
      season: lastAvailableSeason,
      episode: lastAvailableEpisode,
    };
  };

  return {
    getLastAvailableEpisode,
    getFreeEpisode,
    getEpisodeById,
    getFirstAvailableEpisode,
    getNextAvailableEpisode,
  };
}
